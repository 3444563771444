import * as d3 from 'd3';
import eliminateExtraWhiteSpace from '../utils/eliminateExtraWhiteSpace';
import toastMessage from '../utils/toastMessage/toastMessageUtility';
import { handleLogout } from './Authentication';
import apiBaseUrl from '../utils/apiBaseUrl';

const logoutAndReload = async () => {
  await handleLogout();
  window.location.reload();
};

export const getEmployeeDataById = async (activeAccount, personId, currentView) => {
  let parsedResponse;
  const response = await fetch(
    `${apiBaseUrl}/hierarchy/${personId}/${activeAccount.name}?relationship_view=${currentView.viewName}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 401) {
    await logoutAndReload();
  } else {
    parsedResponse = await d3.csvParse(await response.text());
    /*
    await response
      .json()
      .then((r) => {
        if (r.statusCode === 200) {
          returnJson = r.status;
        }
      })
      .catch(() => {
        toastMessage.error('Fetching person data failed, please try again');
      });
    */
  }
  return parsedResponse;
};

export const getEmployeeData = async (searchTerm, signal) => {
  let returnJson;
  const response = await fetch(
    `${apiBaseUrl}/people/search?query=${eliminateExtraWhiteSpace(searchTerm)}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      signal,
    },
  );
  if (response.status === 401) {
    await logoutAndReload();
  } else {
    await response
      .json()
      .then((r) => {
        if (r.statusCode === 200) {
          returnJson = r.result;
        }
      })
      .catch(() => {
        toastMessage.error('Fetching result list failed, please try again');
      });
  }
  return returnJson;
};

export const getOrphanData = async (activeAccount, isConsultant) => {
  let returnJson;
  const response = await fetch(
    `${apiBaseUrl}/orphans/${activeAccount.name}?isConsultant=${isConsultant}`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
  if (response.status === 401) {
    await logoutAndReload();
  } else {
    await response
      .json()
      .then((r) => {
        if (r.statusCode === 200) {
          returnJson = r.status;
        }
      })
      .catch(() => {
        toastMessage.error('Failed to get orphan data');
      });
  }
  return returnJson;
};
